import { useContext } from "react";

import Form from "Clutch/Atoms/Form";
import Divider from "Clutch/Atoms/Divider";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import Svg from "Clutch/Atoms/Svg";
import Button from "Clutch/Molecules/Button";
import Panel from "Clutch/Atoms/Panel";

import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import useFeatureFlag from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";

import styles from "./MobileYmmSelector.module.scss";

const MobileYmmSelector = ({ suppressOtherFitment, audience }) => {
  const { setYmmModal } = useContext(NavigationContext);
  const setCurrentTab = useVehicleStore((x) => x.setCurrentTab);
  const { dispatch: navigationDispatch } = useContext(NavigationContext);

  const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag");
  const customerProjectDrawerEnabled = useFeatureFlag(
    "FrontEnd_CustomerProject_Drawer_Enabled"
  );
  const ymmSectionTreatmentA = useFeatureFlag("HomePage_YmmSelector_Redesign");

  if (ymmSectionTreatmentA) {
    return (
      <div className={styles.wrapper}>
        {((suppressOtherFitment && audience !== "race") ||
          !suppressOtherFitment) && (
          <div
            className={styles.overlay}
            onClick={() => {
              if (customerProjectDrawerEnabled) {
                navigationDispatch({
                  type: "setCustomerProjectDrawerState",
                  payload: {
                    garageState: {
                      isCustomerProjectDrawerOpen: true,
                      customerProjectDrawerView: "ADD_YMM",
                    },
                  },
                });
              } else {
                setYmmModal(true);
                setCurrentTab("street");
              }
            }}
          >
            <Form.Select
              className={styles.select}
              placeholder={"Choose Year"}
              aria-label={"Choose Year"}
              displayLabel={false}
            ></Form.Select>
            <Form.Select
              className={styles.select}
              placeholder={"Choose Make"}
              aria-label={"Choose Make"}
              displayLabel={false}
            ></Form.Select>
            <Form.Select
              className={styles.select}
              placeholder={"Choose Model"}
              aria-label={"Choose Model"}
              displayLabel={false}
            ></Form.Select>
          </div>
        )}

        {!suppressOtherFitment && (
          <>
            <div className={styles.orAdd}>
              <Divider className={styles.divider} />
              <Typography tone={"contrast"} font={"bold"} size={1.25}>
                Or Add
              </Typography>
              <Divider className={styles.divider} />
            </div>
            <div className={styles.buttonWrapper}>
              <div
                className={styles.overlay}
                onClick={() => {
                  if (customerProjectDrawerEnabled) {
                    navigationDispatch({
                      type: "setCustomerProjectDrawerState",
                      payload: {
                        garageState: {
                          isCustomerProjectDrawerOpen: true,
                          customerProjectDrawerView: "ADD_RACETYPE",
                        },
                      },
                    });
                  } else {
                    setYmmModal(true);
                    setCurrentTab("race");
                  }
                }}
              >
                <TextLink className={styles.textLink}>
                  <Svg icon={"flag-checkered"} intent={"subdued"} size={1} />
                  <Typography font={"bold"} tone={"contrast"}>Race Car</Typography>
                </TextLink>
              </div>
              {customerProjectDrawerEnabled && (
                <>
                  <Typography>|</Typography>
                  <div
                    className={styles.overlay}
                    onClick={() => {
                      navigationDispatch({
                        type: "setCustomerProjectDrawerState",
                        payload: {
                          garageState: {
                            isCustomerProjectDrawerOpen: true,
                            customerProjectDrawerView: "ADD_ENGINE",
                          },
                        },
                      });
                    }}
                  >
                    <TextLink className={styles.textLink}>
                      <Svg icon={"v8-engine"} intent={"subdued"} size={1} />
                      <Typography font={"bold"} tone={"contrast"}>Engine</Typography>
                    </TextLink>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {suppressOtherFitment && audience === "race" && (
          <div
            className={styles.overlay}
            onClick={() => {
              setYmmModal(true);
              setCurrentTab("race");
            }}
          >
            <Form.Select
              placeholder={"Choose your Race Car"}
              aria-label={"Choose your Race Car"}
              displayLabel={false}
            ></Form.Select>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {((suppressOtherFitment && audience === "street") ||
        !suppressOtherFitment) && (
        <>
          <Button
            intent={"subdued"}
            segmentEvent={createClickedSegmentEvent("HeroSectionAddYMM")}
            size={"small"}
            fill
            onClick={() => {
              if (customerProjectDrawerEnabled) {
                navigationDispatch({
                  type: "setCustomerProjectDrawerState",
                  payload: {
                    garageState: {
                      isCustomerProjectDrawerOpen: true,
                      customerProjectDrawerView: "ADD_YMM",
                    },
                  },
                });
              } else {
                setYmmModal(true);
                setCurrentTab("street");
              }
            }}
          >
            <Panel transparent inverseTheme className={styles.panel}>
              <div className={styles.buttonContent}>
                <div className={styles.buttonContent}>
                  <Svg icon={"car"} size={1} intent={"action"} />
                  <Typography font={"bold"}>Add Year/Make/Model</Typography>
                </div>
                <Svg icon={"chevron"} size={1.25} intent={"action"} />
              </div>
            </Panel>
          </Button>
        </>
      )}
      {((suppressOtherFitment && audience === "race") ||
        !suppressOtherFitment) && (
        <>
          <Button
            intent={"subdued"}
            segmentEvent={createClickedSegmentEvent("HeroSectionAddRT")}
            size={"small"}
            onClick={() => {
              if (customerProjectDrawerEnabled) {
                navigationDispatch({
                  type: "setCustomerProjectDrawerState",
                  payload: {
                    garageState: {
                      isCustomerProjectDrawerOpen: true,
                      customerProjectDrawerView: "ADD_RACETYPE",
                    },
                  },
                });
              } else {
                setYmmModal(true);
                setCurrentTab("race");
              }
            }}
          >
            <Panel transparent inverseTheme className={styles.panel}>
              <div className={styles.buttonContent}>
                <div className={styles.buttonContent}>
                  <Svg icon={"flag-checkered"} size={1} intent={"action"} />
                  <Typography font={"bold"}>Add a Race Car</Typography>
                </div>
                <Svg icon={"chevron"} size={1.25} intent={"action"} />
              </div>
            </Panel>
          </Button>
        </>
      )}
      {!suppressOtherFitment && customerProjectDrawerEnabled && 
        <>
          <Button
            intent={"subdued"}
            segmentEvent={createClickedSegmentEvent("HeroSectionAddEngine")}
            size={"small"}
            onClick={() => {
                console.log("New Engine Interaction")
                navigationDispatch({
                  type: "setCustomerProjectDrawerState",
                  payload: {
                    garageState: {
                      isCustomerProjectDrawerOpen: true,
                      customerProjectDrawerView: "ADD_ENGINE",
                    },
                  },
                });
            }}
          >
            <Panel transparent inverseTheme className={styles.panel}>
              <div className={styles.buttonContent}>
                <div className={styles.buttonContent}>
                  <Svg icon={"v8-engine"} size={1} intent={"action"} />
                  <Typography font={"bold"}>Add an Engine</Typography>
                </div>
                <Svg icon={"chevron"} size={1.25} intent={"action"} />
              </div>
            </Panel>
          </Button>
        </>
      }
    </div>
  );
};

export default MobileYmmSelector;
